import React from 'react';
import ReactFlow, { MiniMap, Controls, Background } from 'reactflow';
import 'reactflow/dist/style.css';
import { Link, useNavigate } from 'react-router-dom';
import Footer from './Footer';

const onNodeClick = (event, node, navigate) => {
    if (node.data && node.data.link) {
        if (node.data.link.startsWith('http')) {
            window.open(node.data.link, '_blank');
        } else {
            navigate(node.data.link);
        }
    }
};

const SiteMap = () => {
    const navigate = useNavigate();

const nodes = [
  { id: '1', data: { label: <Link to="/">UEntertainment</Link> }, position: { x: 610, y: 30 }, style: { background: 'linear-gradient(to bottom, #FAEAA1 30%, #BA8A41, #C5973D)', color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '1px' }},
  { id: '2', data: { label: <Link to="/">Home</Link> }, position: { x: 120, y: 120 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '3', data: { label: <Link to="/about-us">About Us</Link> }, position: { x: 500, y: 120 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '4', data: { label: <Link to="/contact">Contact Us</Link> }, position: { x: 740, y: 120 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '5', data: { label: <Link to="/">Privacy Policy</Link> }, position: { x:980, y: 120 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '6', data: { label: <Link to="/">Terms and Conditions</Link> }, position: { x: 1220, y: 120 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '7', data: { label: 'Group Sites' }, position: { x: 20, y: 200 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '8', data: { label: 'Additional' }, position: { x: 260, y: 200 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '9', data: { label: 'U Stream' ,link: 'https://stream.uentertainment.com'}, position: { x: 20, y: 300 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '10', data: { label: 'U Music' , link: 'https://music.uentertainment.com'}, position: { x: 20, y: 350 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '11', data: { label: 'U Digital', link: 'https://digital.uentertainment.com' }, position: { x: 20, y: 400 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '12', data: { label: 'U Magazine' , link: 'https://magazine.uentertainment.com'}, position: { x: 20, y: 450 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '13', data: { label: 'U Media', link: 'https://media.uentertainment.com' }, position: { x: 20, y: 500 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '14', data: { label: 'U Productions' , link: 'https://productions.uentertainment.com' }, position: { x: 20, y: 550 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '15', data: { label: 'U Cinema' , link: 'https://cinema.uentertainment.com'}, position: { x: 20, y: 620 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '16', data: { label: 'U Events', link: 'https://events.uentertainment.com' }, position: { x: 20, y: 670 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '17', data: { label: 'U Studios', link: 'https://studios.uentertainment.com' }, position: { x: 20, y: 720 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '18', data: { label: 'U Originals', link: 'https://originals.uentertainment.com' }, position: { x: 20, y: 770 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '19', data: { label: 'U Live' , link: 'https://live.uentertainment.com'}, position: { x: 20, y: 820 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '20', data: { label: 'U Distribution', link: 'https://distribution.uentertainment.com' }, position: { x: 20, y: 870 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '21', data: { label: 'News' }, position: { x: 260, y: 300 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '22', data: { label: 'Photos' }, position: { x: 260, y: 350 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '23', data: { label: 'Videos' }, position: { x: 260, y: 400 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '24', data: { label: 'Interviews' }, position: { x: 260, y: 450 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '25', data: { label: 'Features' }, position: { x: 260, y: 500 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '26', data: { label: 'Reviews' }, position: { x: 260, y: 550 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '27', data: { label: 'Overview' }, position: { x: 500, y: 200 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '28', data: { label: 'Why Choose Us' }, position: { x: 500, y: 250 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '29', data: { label: 'Our Vision' }, position: { x: 500, y: 300 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '30', data: { label: 'Map' }, position: { x: 740, y: 200 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '31', data: { label: 'Contact Form' }, position: { x: 740, y: 250 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
  { id: '32', data: { label: 'Contact Details' }, position: { x: 740, y: 300 }, style: {  background: 'linear-gradient(to bottom, #EDEEEF 40%, #96989A, #9A9C9E)',  color: '#000', padding: 10, borderRadius: 5  ,fontSize: '18px',  fontWeight: 600,letterSpacing: '2px' }},
];

const edges = [
  { id: 'e1', source: '1', target: '2', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e2', source: '1', target: '3', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e3', source: '1', target: '4', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e4', source: '1', target: '5', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e5', source: '1', target: '6', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e6', source: '2', target: '7', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e7', source: '2', target: '8', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e8', source: '7', target: '9', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e9', source: '9', target: '10', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e10', source: '10', target: '11', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e11', source: '11', target: '12', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e12', source: '12', target: '13', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e13', source: '13', target: '14', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e14', source: '14', target: '15', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e15', source: '15', target: '16', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e16', source: '16', target: '17', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e17', source: '17', target: '18', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e18', source: '18', target: '19', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e19', source: '19', target: '20', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e20', source: '8', target: '21', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e21', source: '21', target: '22', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e22', source: '22', target: '23', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e23', source: '23', target: '24', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e24', source: '24', target: '25', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e25', source: '25', target: '26', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e26', source: '3', target: '27', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e27', source: '27', target: '28', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e28', source: '28', target: '29', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e29', source: '4', target: '30', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e30', source: '30', target: '31', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
  { id: 'e31', source: '31', target: '32', animated: true, style: { stroke: '#000', strokeWidth: 2 }},
];

  return (
    <>
    <div style={{ height: '500px', width: '100%' }}>
      <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodeClick={(event, node) => onNodeClick(event, node, navigate)}
            style={{ width: '100%', height: '100vh' }}
        >
        <MiniMap
          nodeColor={(node) => {
            switch (node.id) {
              case '1': return '#84A8F6';
              case '2': return '#9CDAC7';
              case '3': return '#F78383';
              case '4': return '#FFCA00';
              case '5': return '#BD84F5';
              case '6': return '#F9C984';
              case '7': return '#84A8F6';
              case '8': return '#84A8F6';
              default: return '#000';
            }
          }}
        />
        <Controls />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
    <Footer/>
    </>
  );
};

export default SiteMap;
