import React, { useState } from 'react';
import { toast } from 'react-toastify'; 
import "react-toastify/dist/ReactToastify.css";  
import "../css/Global.css";
import Footer from './Footer';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://localhost:5000/contact', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            const data = await response.json();

            if (response.ok) {
                toast.success(data.message);  
                setFormData({ name: '', email: '', mobile: '', message: '' });
            } else {
                toast.error(data.error || "Submission failed.");  
            }
        } catch (error) {
            toast.error("An error occurred. Please try again."); 
        }
    };

    return (
        <>
            <div className="contact-component">
                <h2>Contact us</h2>
            </div>
            <div className="contact-section">
                <div className="contact-container">
                    <div className="contact-map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.3496420571314!2d72.82885907520686!3d19.136166482080792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b622e3f574b9%3A0x4d70c23fd08eb6!2sLaxmi%20Business%20Park!5e0!3m2!1sen!2sin!4v1733143662672!5m2!1sen!2sin"
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </div>
                    <div className="contact-form">
                        <form onSubmit={handleSubmit}>
                            <label>
                                Name
                                <input type="text" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} required />
                            </label>
                            <label>
                                Email
                                <input type="email" name="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} required />
                            </label>
                            <label>
                                Mobile Number
                                <input type="tel" name="mobile" placeholder="Enter your mobile number" value={formData.mobile} onChange={handleChange} required />
                            </label>
                            <label>
                                Message
                                <textarea name="message" placeholder="Enter your message" value={formData.message} onChange={handleChange} required></textarea>
                            </label>
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="contact-details">
                <div className="contact-card">
                    <h3>Address</h3>
                    <p>New Link Road, Veera Desai Industrial Estate,
                    Andheri West, Mumbai, Maharashtra - 400053</p>
                </div>
                <div className="contact-card">
                    <h3>Call Us</h3>
                   <div className="contact-p">
                   <p>Contact No: +91 9238444400</p>
                   <p>Helpline: <span></span>&nbsp;&nbsp;+91 9312412422 </p>
                   </div>
                </div>
                <div className="contact-card">
                    <h3>Email Us</h3>
                    <div className="contact-p">
                    <p>contact@uentertainment.com</p>
                    <p>enquiry@uentertainment.com</p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contact;
