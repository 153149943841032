import React from 'react';
import Footer from './Footer';

const PrivacyPolicy = () => {
  return (
    <>
    <div className='legal'>
      <h1>Privacy Policy for UEntertainment</h1>

      <h2>1. Scope of Application and Use</h2>
      <p><strong>General Scope:</strong> This Privacy Policy outlines the terms and conditions for UEntertainment, specifying how we collect, use, and protect user data across all our digital platforms, including mobile applications, websites, and affiliated services. It applies to all users, including registered members and guest visitors.</p>
      <p><strong>User Consent and Acceptance:</strong> By using UEntertainment, users agree to our Privacy Policy. They acknowledge that their information may be processed as described, and they commit to using UEntertainment responsibly and within the bounds of this policy.</p>
      <p><strong>Geographic Limitations:</strong> UEntertainment services may be limited by geography. For instance, users within the European Union will be subject to GDPR, impacting data collection and usage practices. We respect these restrictions and provide service in compliance with relevant local regulations.</p>
      <p><strong>Third-Party Interaction:</strong> UEntertainment may contain links to third-party sites or offer features connected to other services. Users interacting with these should review the privacy policies of these third parties, as UEntertainment has no control over external content.</p>
      <p><strong>Policy Updates:</strong> UEntertainment reserves the right to update this Privacy Policy to reflect changes in our services or legal obligations. Users will be notified of significant changes, but they are encouraged to review this policy periodically to stay informed of updates.</p>

      <h2>2. Usage Options</h2>
      <p><strong>Account Creation:</strong> Users can create accounts to access personalized services, manage preferences, and enjoy a customized experience. This involves creating a unique username and password and may require additional information like email or phone number.</p>
      <p><strong>Data Collection Choices:</strong> Users can choose what data they share with UEntertainment. They may opt out of data collection, tracking, or cookie-based data collection via account settings or browser options, though this may affect service quality.</p>
      <p><strong>Communication Preferences:</strong> UEntertainment allows users to manage preferences regarding notifications and communication. They can opt-in or opt-out of receiving newsletters, promotional offers, or service alerts.</p>
      <p><strong>Ad Personalization:</strong> Users have the option to enable or disable ad personalization. Enabling this feature allows UEntertainment to deliver tailored ads based on user activity and interests; however, users can opt-out if preferred.</p>
      <p><strong>Profile Visibility:</strong> User profiles may have visibility settings to control what information is publicly accessible. This option enables users to limit or expand profile visibility to specific audiences within the platform.</p>
      <p><strong>Data Portability:</strong> Users can request their data in a portable format for personal use or transfer to other services. This ensures that users maintain control over their data and have options to manage it effectively.</p>
      <p><strong>Account Settings Customization:</strong> UEntertainment offers customization options for account appearance, language, and other settings, allowing users to personalize their experience.</p>
      <p><strong>Two-Factor Authentication:</strong> Users may activate two-factor authentication (2FA) for enhanced account security. This feature requires an additional verification code upon login, adding a layer of protection to user data.</p>
      <p><strong>Parental Controls:</strong> Parental control features enable guardians to manage the content and services available to minors, helping maintain safe and appropriate experiences on UEntertainment.</p>
      <p><strong>Account Suspension or Termination:</strong> Users may choose to deactivate or permanently delete their accounts. Upon termination, UEntertainment will remove user data in line with data retention policies and legal requirements.</p>

      <h2>3. Liability</h2>
      <p><strong>Service Availability:</strong> UEntertainment strives to maintain reliable service availability but cannot guarantee uninterrupted access due to factors beyond control, such as maintenance or technical issues.</p>
      <p><strong>User-Generated Content:</strong> Users are responsible for content they generate, upload, or share on UEntertainment. Any violations or liabilities resulting from user content are the user’s responsibility, not UEntertainment’s.</p>
      <p><strong>Third-Party Links:</strong> UEntertainment is not responsible for external websites or services linked within the platform. Users interacting with third-party links should be aware that they are subject to those sites' policies, not UEntertainment’s.</p>
      <p><strong>Data Security:</strong> While UEntertainment implements rigorous security measures, it cannot guarantee absolute data security. Users assume the risk associated with data transmission over the internet.</p>
      <p><strong>User Data:</strong> UEntertainment is not liable for any data loss arising from user actions or account mismanagement, such as accidental deletions or compromised credentials.</p>
      <p><strong>Intellectual Property:</strong> UEntertainment is not liable for intellectual property rights issues arising from content posted by users. Users agree to respect IP laws and ensure their content does not infringe on third-party rights.</p>
      <p><strong>Personal Injury or Damage:</strong> UEntertainment disclaims responsibility for personal injuries or damages resulting from the misuse of its services or interactions with other users.</p>
      <p><strong>Loss of Earnings:</strong> UEntertainment is not responsible for any business or financial losses users may incur due to service use, disruptions, or outages.</p>
      <p><strong>Non-Guarantee of Service:</strong> The platform is provided “as is” without warranty. UEntertainment disclaims any implied warranties regarding fitness for a particular purpose.</p>
      <p><strong>Dispute Resolution:</strong> Users agree to resolve disputes amicably, potentially through binding arbitration, in line with applicable jurisdictional laws.</p>

      <h2>4. Service Content, Software, and Copyright</h2>
      <p><strong>Ownership of Content:</strong> UEntertainment holds exclusive rights to its software, content, and trademarks. Unauthorized reproduction, distribution, or use is prohibited.</p>
      <p><strong>User Rights to Access:</strong> UEntertainment grants users a limited license to use its content and services, with restrictions that prohibit reverse engineering, duplication, or redistribution without permission.</p>
      <p><strong>Prohibited Uses:</strong> Unauthorized activities such as copying, selling, or modifying UEntertainment’s intellectual property without explicit consent are strictly forbidden.</p>
      <p><strong>User-Generated Content License:</strong> By posting content, users grant UEntertainment permission to display, modify, or distribute it for service-related purposes, as legally permitted.</p>
      <p><strong>Copyright Infringement Reporting:</strong> UEntertainment provides a reporting mechanism for copyright infringements. Reports can be directed to UEntertainment’s designated copyright agent for review.</p>

      <h2>5. Data Privacy</h2>
      <p><strong>Data Collection:</strong> UEntertainment collects data for personalization and service optimization, including personal details, usage behavior, and technical logs. Data collection is conducted in compliance with applicable laws.</p>
      <p><strong>Data Usage and Sharing:</strong> User data is used to improve user experience and may be shared with trusted third-party service providers, but only as needed to deliver core services.</p>
      <p><strong>Data Security and Retention:</strong> UEntertainment employs industry-standard security practices to protect user data. Data retention policies ensure data is only stored as long as necessary, after which it is securely deleted or anonymized.</p>

      <h2>6. Miscellaneous</h2>
      <p><strong>Governing Law:</strong> This Privacy Policy is governed by the laws applicable to the jurisdiction of UEntertainment's headquarters, which will prevail in case of any disputes.</p>
      <p><strong>Severability Clause:</strong> If any provision of this policy is found invalid or unenforceable, other provisions remain intact and enforceable, preserving the policy’s intent.</p>
      <p><strong>Contact Information:</strong> Users with questions or concerns regarding this Privacy Policy can reach out via the provided contact details for UEntertainment’s customer service or legal team.</p>
    </div>
    <Footer />
    </>
  );
};

export default PrivacyPolicy;
