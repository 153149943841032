import React, { useState, useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Home from './Components/Home';
import AboutUs from './Components/About';
import Contact from './Components/Contact';
import ScrollToTop from './Components/ScrollToTop';
import SiteMap from './Components/SiteMap';
import PrivacyPolicy from './Components/Privacy_Policy';
import TermsAndConditions from './Components/Terms-Conditions';
import Preloader from './Components/Preloader'

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 3000); // Simulate a 3-second loading
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <div className="App">
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/site-map" element={<SiteMap />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </div>
  );
}

export default App;
