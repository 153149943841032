import React from 'react';
import Footer from './Footer';

const TermsAndConditions = () => {
  return (
    <>
    <div className='legal'>
      <h1>Terms and Conditions for UEntertainment</h1>

      <h2>1. Acceptance of Terms</h2>
      <ul>
        <li>By using UEntertainment, you acknowledge and agree to be bound by these Terms. Your continued use constitutes acceptance of any changes made to the Terms.</li>
        <li>We may update these Terms from time to time. If there are substantial changes, we will provide notifications on our website.</li>
        <li>Failure to adhere to any part of these Terms may result in restrictions or suspension of access to UEntertainment services.</li>
        <li>All users must agree to comply with our Privacy Policy as part of these Terms.</li>
        <li>If you do not accept these Terms, you must immediately cease using UEntertainment.</li>
      </ul>

      <h2>2. Eligibility and User Responsibility</h2>
      <ul>
        <li>Users must be at least 18 years old or possess legal consent from a parent/guardian to use UEntertainment.</li>
        <li>Users are responsible for ensuring the accuracy of the information provided during registration.</li>
        <li>You must promptly notify UEntertainment of any unauthorized use or security breach related to your account.</li>
        <li>All actions taken under a user's account are the responsibility of that account holder.</li>
        <li>Users are accountable for maintaining secure and updated credentials for their UEntertainment account.</li>
      </ul>

      <h2>3. Account Registration and Management</h2>
      <ul>
        <li>Creating an account requires providing accurate and up-to-date information, which must be maintained regularly.</li>
        <li>Each user is responsible for safeguarding their account credentials. Sharing accounts is strictly prohibited.</li>
        <li>Any misuse of another person’s account is a violation and will result in suspension.</li>
        <li>UEntertainment reserves the right to terminate accounts if the provided information is found to be false or misleading.</li>
        <li>All account activity, including any third-party access through integrations, is the sole responsibility of the account owner.</li>
      </ul>

      <h2>4. Prohibited Conduct</h2>
      <ul>
        <li>Users are prohibited from engaging in any illegal activities, including but not limited to fraud or harassment.</li>
        <li>Spamming, phishing, and unauthorized access to other users' data or accounts are strictly prohibited.</li>
        <li>Users must not upload, post, or share any content that is unlawful, harmful, or abusive.</li>
        <li>Unauthorized advertising, such as unsolicited promotional messages, is prohibited on UEntertainment.</li>
        <li>Any attempt to disrupt or interfere with the UEntertainment services, such as through hacking or injecting malicious software, is forbidden.</li>
      </ul>

      <h2>5. Service Availability and Modifications</h2>
      <ul>
        <li>While UEntertainment strives for high availability, we cannot guarantee that services will be uninterrupted or error-free.</li>
        <li>We reserve the right to modify, suspend, or terminate any part of the service at any time without prior notice.</li>
        <li>UEntertainment may restrict access to certain features or impose limitations based on various factors, such as usage or availability.</li>
        <li>Users may experience downtime during scheduled maintenance or unforeseen technical issues.</li>
        <li>We are not liable for any loss or inconvenience arising from service interruptions.</li>
      </ul>

      <h2>6. Content Ownership and Licensing</h2>
      <ul>
        <li>All content on UEntertainment, including text, images, software, and designs, is protected by copyright and other intellectual property laws.</li>
        <li>UEntertainment grants users a limited license to access and use the content solely for personal, non-commercial purposes.</li>
        <li>Redistributing or republishing any content without explicit permission from UEntertainment is prohibited.</li>
        <li>Users must not attempt to reverse-engineer, decompile, or tamper with any software or features provided by UEntertainment.</li>
        <li>We reserve the right to terminate any license granted if users violate these Terms.</li>
      </ul>

      <h2>7. User-Generated Content</h2>
      <ul>
        <li>Users may share, post, or upload content to UEntertainment, provided they have the necessary rights to do so.</li>
        <li>By uploading content, users grant UEntertainment a worldwide, royalty-free license to use, modify, and display the content in connection with the service.</li>
        <li>Users retain ownership of their content but are responsible for ensuring it complies with these Terms and applicable laws.</li>
        <li>UEntertainment has the right to review and remove any user content that violates these Terms or is otherwise deemed inappropriate.</li>
        <li>Content shared by users should not infringe on the rights of third parties, including intellectual property or privacy rights.</li>
      </ul>

      <h2>8. Intellectual Property Rights</h2>
      <ul>
        <li>All trademarks, service marks, and logos displayed on UEntertainment are the property of UEntertainment or its licensors.</li>
        <li>Unauthorized use of UEntertainment's intellectual property is strictly prohibited.</li>
        <li>Users may not copy or reproduce any part of UEntertainment's branding or design without written permission.</li>
        <li>UEntertainment respects the intellectual property rights of others and expects users to do the same.</li>
        <li>Users should report any suspected infringement of intellectual property on UEntertainment’s platform to our support team.</li>
      </ul>

      <h2>9. Third-Party Links and Services</h2>
      <ul>
        <li>UEntertainment may include links to third-party websites or services. These links do not imply endorsement or approval by UEntertainment.</li>
        <li>We are not responsible for the content, accuracy, or practices of third-party websites or services.</li>
        <li>Users are advised to review the terms and privacy policies of any third-party sites they access through UEntertainment.</li>
        <li>UEntertainment does not guarantee the availability or performance of third-party integrations.</li>
        <li>Engagement with third-party sites or services is at the user’s own risk.</li>
      </ul>

      <h2>10. Payment and Subscription Terms</h2>
      <ul>
        <li>Some UEntertainment services may require payment or subscription fees, which are clearly outlined at the time of purchase.</li>
        <li>By subscribing, users agree to pay all associated fees in a timely manner.</li>
        <li>UEntertainment reserves the right to modify subscription fees or add new charges with prior notice.</li>
        <li>All payments are final and non-refundable unless specified otherwise or required by law.</li>
        <li>Users must ensure that their payment information is accurate and up-to-date to avoid service interruptions.</li>
      </ul>

      <h2>11. Refund Policy</h2>
      <ul>
        <li>Refunds are issued only in cases of service errors or where mandated by applicable law.</li>
        <li>Refund requests should be submitted within a specified timeframe after the transaction.</li>
        <li>Users must provide all necessary details for a refund to be processed successfully.</li>
        <li>Refunds will be processed back to the original payment method within a specified timeframe.</li>
        <li>UEntertainment reserves the right to deny refunds if abuse or misuse of the service is suspected.</li>
      </ul>

      <h2>12. Disclaimers and Limitation of Liability</h2>
      <ul>
        <li>UEntertainment is provided on an "as is" basis without warranties of any kind.</li>
        <li>We do not guarantee that the service will be uninterrupted, error-free, or secure at all times.</li>
        <li>UEntertainment is not liable for any direct, indirect, incidental, or consequential damages arising from use of our services.</li>
        <li>We are not responsible for any loss of data or damages due to unauthorized access to your account.</li>
        <li>Some jurisdictions may not allow the exclusion of certain warranties, so these exclusions may not apply to you.</li>
      </ul>

      <h2>13. Indemnification</h2>
      <ul>
        <li>Users agree to indemnify UEntertainment against claims, damages, and expenses arising from their use of our services.</li>
        <li>This indemnification includes costs resulting from user violations of these Terms or third-party rights.</li>
        <li>UEntertainment reserves the right to assume exclusive defense of any matter subject to indemnification.</li>
        <li>Users agree to cooperate with UEntertainment in any defense against claims related to indemnification.</li>
      </ul>

      <h2>14. Privacy and Data Collection</h2>
      <ul>
        <li>UEntertainment is committed to user privacy and follows a strict Privacy Policy to protect personal data.</li>
        <li>By using our services, users consent to data collection and usage as outlined in our Privacy Policy.</li>
        <li>UEntertainment does not sell personal data to third parties without explicit consent.</li>
        <li>Data collected is used to enhance user experience and improve service offerings.</li>
        <li>Users have rights regarding their data, which they can exercise as per our Privacy Policy.</li>
      </ul>

      <h2>15. Changes to Terms</h2>
      <ul>
        <li>UEntertainment reserves the right to modify these Terms periodically.</li>
        <li>Any changes to the Terms will be posted on our website with an updated effective date.</li>
        <li>Continued use of the services after changes implies acceptance of the new Terms.</li>
        <li>Users are encouraged to review these Terms regularly to stay informed of any updates.</li>
        <li>If users do not agree with the changes, they must stop using UEntertainment services.</li>
      </ul>

      <h2>16. Governing Law</h2>
      <ul>
        <li>These Terms are governed by the laws of the jurisdiction in which UEntertainment operates.</li>
        <li>All disputes arising from these Terms will be subject to the exclusive jurisdiction of local courts.</li>
        <li>If any provision of these Terms is found to be unenforceable, the remaining provisions will remain valid.</li>
        <li>Users agree to resolve any disputes amicably before pursuing legal action.</li>
        <li>These Terms constitute the entire agreement between UEntertainment and the user, superseding prior agreements.</li>
      </ul>
    </div>
    <Footer />
    </>
  );
};

export default TermsAndConditions;
