import React from "react";
import "../css/Global.css";
import PreloaderImg from '../img/logo_Entertainment_preloader.gif';

const Preloader = () => {
  return (
    <div className="preloader-container">
      <div className="preloader">
        <img src={PreloaderImg} alt="Loading..." />
      </div>
    </div>
  );
};

export default Preloader;
