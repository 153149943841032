import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Global.css';
import logo from '../img/U_Entertainment.gif';


const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const navigate = useNavigate();

  const handleNavigate = () => {
    window.open('https://wa.me/919238444400', '_blank', 'noopener,noreferrer');
  }

  return (
    <header className="header">
      <div className="header-row logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="header-row links">
        <div className="hamburger" onClick={toggleDrawer}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>

        <div className={`nav-link-drawer ${isDrawerOpen ? 'open' : ''}`}>
          <div className="nav-links">
            <ul>
              <li><Link to="/" onClick={closeDrawer}>Home</Link></li>
              <li><Link to="/about-us" onClick={closeDrawer}>About Us</Link></li>
              <li><Link to="/contact" onClick={closeDrawer}>Contact Us</Link></li>
            </ul>
          </div>
        </div>

        {/* Social Links */}
        <div className="follow-links">
          <p className='follow'>Follow Us</p>
          <div className="social-links">
            <i className="fa-brands fa-facebook"></i>
            <i className="fa-brands fa-instagram"></i>
            <i className="fa-brands fa-youtube"></i>
            <i className="fa-brands fa-x-twitter"></i>
            <i className="fa-brands fa-whatsapp" onClick={handleNavigate}></i>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
